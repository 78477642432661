<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import LoadingService from '@/services/LoadingService'
import TranslateService from '@/services/TranslateService'
import ChartsDataService from '@/services/ChartsDataService'
import AnalyticDataService from '@/services/AnalyticDataService'
import FullscreenViewerService from '@/services/FullscreenViewerService'
import ContentCard from '@/components/ContentCard.vue'
import CardTop from '@/components/CardTop.vue'
import NameplatesList from '@/components/NameplatesList.vue'
import ImageLoader from '@/components/ImageLoader.vue'
import CannotCompareData from '@/components/CannotCompareData.vue'
import Heatmap from '@/components/Heatmap.vue'
import SubSectionsCounter from '@/components/SubSectionsCounter.vue'
import PieChart from '@/components/Charts/PieChart.vue'
import BarChart from '@/components/Charts/BarChart.vue'
import BubbleChart from '@/components/Charts/BubbleChart.vue'
import ChartSelector from '@/components/ChartSelector.vue'
import ChartSelectorDisabled from '@/components/ChartSelectorDisabled.vue'
import CompareShowTitles from '@/components/CompareShowTitles.vue'
import PieChartLegend from '@/components/PieChartLegend.vue'
import PercentagesDisclaimer from '@/components/PercentagesDisclaimer.vue'
import DemographyPieCharts from '@/components/DemographyPieCharts.vue'

export default {
  name: 'TrafficSectionMixin',
  components: {
    Heatmap,
    PieChart,
    BarChart,
    BubbleChart,
    NameplatesList,
    ImageLoader,
    CannotCompareData,
    ContentCard,
    SubSectionsCounter,
    CardTop,
    DemographyPieCharts,
    PieChartLegend,
    CompareShowTitles,
    ChartSelector,
    ChartSelectorDisabled,
    PercentagesDisclaimer
  },
  props: {
    pdfArea: {
      type: [String, Boolean],
      default: false
    },
    pdfExperience: {
      type: [String, Boolean],
      default: false
    }
  },
  data() {
    const data = {
      selectedSubView: 0,
      selectedSubView2: 0,
      selectedSubView3: 0,
      selectedSubView4: 0,
      selectedSubView5: 0,
      selectedSubView6: 0,
      selectedSubView7: 0,
      selectedSubView8: 0
    }
    return {
      desktopMode: false,
      desktopLayoutReady: false,
      desktopTitles: [],
      ...data,
      subSections: [
        {
          title: 'T_STAND_TRAFFIC',
          id: 'stand-traffic',
          desktopTitle: {
            fullWidth: true
          },
          cardTop: {
            type: 'switch',
            syncVal: data.selectedSubView,
            states: [
              {
                title: 'T_TRAFFIC_BY_DATE',
                icon: this.getIcon('date')
              },
              {
                title: 'T_TRAFFIC_BY_HOUR',
                icon: this.getIcon('hour')
              }
            ]
          }
        },
        {
          title: 'T_STAND_STATISTICS',
          id: 'stand-statistics',
          desktopTitle: {
            hidden: true
          },
          cardTop: {
            type: 'switch',
            syncVal: data.selectedSubView2,
            states: [
              {
                title: 'T_STAND_STATISTICS_BY_DATE',
                icon: this.getIcon('date')
              },
              {
                title: 'T_STAND_STATISTICS_BY_HOUR',
                icon: this.getIcon('hour')
              }
            ]
          }
        },
        {
          title: 'T_AREA_TRAFFIC',
          id: 'area-traffic',
          cardTop: {
            type: 'switch',
            syncVal: data.selectedSubView3,
            states: [
              {
                title: 'T_AREA_TRAFFIC_BY_DATE',
                icon: this.getIcon('date')
              },
              {
                title: 'T_AREA_TRAFFIC_BY_HOUR',
                icon: this.getIcon('hour')
              }
            ]
          }
        },
        {
          title: 'T_AREA_LIST',
          id: 'area-list',
          cardTop: {
            type: 'switch',
            syncVal: data.selectedSubView4,
            states: [
              {
                title: 'T_AREAS_ON_FLOORPLAN',
                icon: this.getIcon('floorplan')
              },
              {
                title: 'T_TRAFFIC_BY_AREAS',
                icon: this.getIcon('area')
              }
            ]
          }
        },
        {
          title: 'T_ATTRACTIVENESS',
          id: 'attractiveness',
          cardTop: {
            type: 'switch',
            syncVal: data.selectedSubView6,
            states: [
              {
                title: 'T_ATTRACTIVENESS',
                icon: this.getIcon('floorplan')
              },
              {
                title: 'T_ATTRACTIVENESS',
                icon: this.getIcon('nameplate')
              }
            ]
          }
        },
        {
          title: 'T_HEATMAP',
          id: 'heatmap',
          cardTop: {
            type: 'switch',
            syncVal: data.selectedSubView5,
            states: [
              {
                title: 'T_HEATMAP',
                icon: this.getIcon('floorplan')
              },
              {
                title: 'T_HEATMAP',
                icon: this.getIcon('nameplate')
              }
            ]
          }
        },
        {
          title: 'T_CAMERAS',
          id: 'cameras',
          cardTop: {
            type: 'hidden',
            syncVal: data.selectedSubView6,
            states: [
              {
                title: 'T_CAMERAS'
              }
            ]
          }
        },
        {
          title: 'T_NEIGHBORS',
          id: 'neighbors',
          desktopTitle: {
            fullWidth: true
          },
          fullWidth: true,
          cardTop: {
            type: 'hidden',
            states: [
              {
                title: 'T_NEIGHBORS'
              }
            ]
          }
        }
      ],
      heatmapPerDay: false,
      heatmapPerDay2: false,
      heatmapForSelectedDay: false,
      heatmapForSelectedDay2: false,
      bubbleForSelectedDay: false,
      bubbleForSelectedDay2: false,
      requestingHeatmapData: false,
      requestingHeatmapData2: false,
      requestingBubbleData: false,
      requestingBubbleData2: false,
      reloadingHeatmap: false,
      reloadingHeatmap2: false,
      reloadingBubble: false,
      reloadingBubble2: false,
      bar: {},
      barHour: {},
      barStayAverage: {},
      barStayAverageHour: {},
      barArea: {},
      barAreaHour: {},
      barAttractiveness: [],
      pie: false,
      pie2: false,
      pieGenders: false,
      pieAgeRanges: false,
      pieGendersByHour: false,
      pieAgeRangesByHour: false,
      pieGenders2: false,
      pieAgeRanges2: false,
      pieGendersByHour2: false,
      pieAgeRangesByHour2: false,
      pieGendersPerArea: false,
      pieAgeRangesPerArea: false,
      pieGendersByHourPerArea: false,
      pieAgeRangesByHourPerArea: false,
      pieGendersPerArea2: false,
      pieAgeRangesPerArea2: false,
      pieGendersByHourPerArea2: false,
      pieAgeRangesByHourPerArea2: false,
      weatherMap: {
        cloudy: '\uE804',
        sunny: '\u2600',
        rainy: '\uE800',
        snowy: '\uE801',
        windy: '\uE805'
      },
      weatherColorMap: {
        cloudy: 'white',
        sunny: '#E1E000',
        rainy: '#71DBD4',
        snowy: 'white',
        windy: 'white'
      },
      cameras: false,
      areas: false,
      areas2: false,
      isPlayingHeatmap: false,
      isPlayingHeatmap2: false,
      playHeatmapInterval: false,
      playHeatmapInterval2: false,
      selectedGroupingValue: 10,
      selectedCameraDevice: 0,
      selectedCameraDay: 0,
      selectedCameraHour: 0,
      initCameras: true,
      highlightedTrafficValue: 0,
      highlightedTrafficHourValue: 0,
      highlightedStayAverage: 0,
      highlightedStayAverageHour: 0,
      highlightedArea: 0,
      highlightedAreaDate: 0,
      highlightedAreaHour: 0,
      highlightedHeatmapDay: 0,
      highlightedHeatmapDay2: 0,
      highlightedBubbleDay: 0,
      highlightedBubbleDay2: 0,
      highlightedAttractiveness: 0,
      highlightedAttractivenessValue: 0,
      highlightedAttractivenessDate: 0,
      highlightedAttractivenessDateValue: 0,
      selectedDate: 0,
      selectedHour: 0,
      selectedStayAverageDate: 0,
      selectedStayAverageHour: 0,
      selectedArea: 0,
      selectedAreaDate: 0,
      selectedAreaHour: 0,
      selectedHeatmapDay: 0,
      selectedHeatmapDay2: 0,
      selectedBubbleDay: 0,
      selectedBubbleDay2: 0,
      selectedAttractiveness: 0,
      selectedAttractiveness2: 0,
      selectedAttractivenessDate: 0,
      selectedAttractivenessDate2: 0,
      highlightedNameplate: -1,
      highlightedNameplate2: -1,
      reloadAreasTable: false,
      bubbleChart: false,
      pointRadius: {
        min: 0,
        max: 35
      },
      areaMappings: {
        highlightedArea: {
          value: 'selectedArea',
          field: 'area'
        },
        highlightedAreaDate: {
          value: 'selectedAreaDate',
          field: 'date'
        },
        highlightedAreaHour: {
          value: 'selectedAreaHour',
          field: 'time'
        }
      },
      stayAverageMappings: {
        highlightedStayAverage: {
          value: 'selectedStayAverageDate',
          field: 'date'
        },
        highlightedStayAverageHour: {
          value: 'selectedStayAverageHour',
          field: 'time'
        }
      },
      pdfPage: false,
      ChartsDataService,
      heatmapChartSelector: [],
      heatmapChartSelector2: [],
      indexesMapping: [
        {
          from: 'lessThan',
          to: 18
        },
        {
          from: 19,
          to: 35
        },
        {
          from: 36,
          to: 55
        },
        {
          from: 56,
          to: 'Max'
        }
      ]
    }
  },
  watch: {
    highlightedTrafficValue(newVal) {
      this.updateSelectedValue(this.bar, 'selectedDate', newVal)
    },
    highlightedTrafficHourValue(newVal) {
      this.updateSelectedValue(this.barHour, 'selectedHour', newVal)
    },
    highlightedStayAverage(newVal) {
      this.updateSelectedValue(
        this.barStayAverage,
        'selectedStayAverageDate',
        newVal,
        'StayAverage'
      )
    },
    highlightedStayAverageHour(newVal) {
      this.updateSelectedValue(
        this.barStayAverageHour,
        'selectedStayAverageHour',
        newVal,
        'StayAverage'
      )
    },
    highlightedArea(newVal) {
      if (this.secondaryData) {
        this.selectedArea = 0
      } else {
        this.selectedArea = _.get(this.areas, `[${this.pdfPage ? newVal - 1 : newVal}]`, 0)
        // console.warn(
        //   `CHANGED AREA =`,
        //   this.pdfPage ? newVal - 1 : newVal,
        //   _.get(
        //     _.find(_.get(this.primaryData, 'abdAreas', []), { _id: this.selectedArea }),
        //     'abdName',
        //     false
        //   ),
        //   _.get(this.areas, `[${this.pdfPage ? newVal - 1 : newVal}]`)
        // )
      }
      this.gotData('Area')
    },
    highlightedAreaDate(newVal) {
      this.updateSelectedValue(this.barArea, 'selectedAreaDate', newVal, 'Area')
    },
    highlightedAreaHour(newVal) {
      this.updateSelectedValue(this.barAreaHour, 'selectedAreaHour', newVal, 'Area')
    },
    highlightedHeatmapDay(newVal) {
      this.selectedHeatmapDay = _.get(this.heatmapPerDay, `[${newVal - 1}].date`, 0)
      this.getHeatmapForSelectedDay()
    },
    highlightedHeatmapDay2(newVal) {
      this.selectedHeatmapDay2 = _.get(this.heatmapPerDay2, `[${newVal - 1}].date`, 0)
      this.getHeatmapForSelectedDay(true)
    },
    highlightedBubbleDay(newVal) {
      this.selectedBubbleDay = _.get(this.heatmapPerDay, `[${newVal - 1}].date`, 0)
      this.getBubbleForSelectedDay()
    },
    highlightedBubbleDay2(newVal) {
      this.selectedBubbleDay2 = _.get(this.heatmapPerDay2, `[${newVal - 1}].date`, 0)
      this.getBubbleForSelectedDay(true)
    },
    highlightedAttractiveness(newVal, oldVal) {
      this.selectedAttractiveness = _.get(
        this.getDataForAttractiveness(this.primaryData),
        `[${newVal - 1}]._id`,
        0
      )
      if (this.selectedAttractiveness === 0 || oldVal === 0) {
        this.highlightedAttractivenessDate = 0
      }
      this.gotData('Attractiveness')
    },
    highlightedAttractiveness2(newVal, oldVal) {
      this.selectedAttractiveness2 = _.get(
        this.getDataForAttractiveness(this.secondaryData),
        `[${newVal - 1}]._id`,
        0
      )
      if (this.selectedAttractiveness2 === 0 || oldVal === 0) {
        this.highlightedAttractivenessDate2 = 0
      }
      this.gotData('Attractiveness')
    },
    highlightedAttractivenessDate(newVal) {
      if (this.selectedAttractiveness === 0) {
        const areaId = _.get(
          this.barAttractiveness,
          `data.datasets[0].areasOrder[${newVal - 1}]`,
          0
        )
        this.selectedAttractivenessDate = _.get(
          _.find(_.get(this.primaryData, 'abdAreas', []), { id: areaId }),
          '_id',
          0
        )
      } else {
        this.selectedAttractivenessDate = _.get(this.heatmapPerDay, `[${newVal - 1}].date`, 0)
      }
      this.gotData('Attractiveness')
    },
    highlightedAttractivenessDate2(newVal) {
      if (this.selectedAttractiveness2 === 0) {
        const areaId = _.get(
          this.barAttractiveness2,
          `data.datasets[0].areasOrder[${newVal - 1}]`,
          0
        )
        this.selectedAttractivenessDate2 = _.get(
          _.find(_.get(this.primaryData, 'abdAreas', []), { id: areaId }),
          '_id',
          0
        )
      } else {
        this.selectedAttractivenessDate2 = _.get(this.heatmapPerDay2, `[${newVal - 1}].date`, 0)
      }
      this.gotData('Attractiveness')
    },
    selectedSubView() {
      this.highlightedTrafficValue = 0
      this.highlightedTrafficHourValue = 0
    },
    selectedSubView2() {
      this.highlightedStayAverage = 0
      this.highlightedStayAverageHour = 0
    },
    selectedSubView3() {
      this.highlightedArea = 0
      this.highlightedAreaDate = 0
      this.highlightedAreaHour = 0
    },
    selectedSubView6() {
      this.highlightedAttractiveness = 0
      this.highlightedAttractivenessDate = 0
    },
    selectedCameraDevice() {
      if (this.getCameraImage() === false) {
        console.warn(`no pic`)
      }
    }
  },
  async mounted() {
    TranslateService.events.on('changeLanguage', this.onChangeLanguage)
    LoadingService.events.on('loading-state', this.onChangeLoading)
  },
  beforeDestroy() {
    TranslateService.events.off('changeLanguage', this.onChangeLanguage)
    LoadingService.events.off('loading-state', this.onChangeLoading)
  },
  methods: {
    hasHeatmap(data) {
      return _.get(data, 'pavilion.hasHeatmap', false)
    },
    hasStayAverage(data) {
      return _.get(data, 'pavilion.hasStayAverage', false)
    },
    getLazySrc(url, resizeOptions) {
      return `${url}?resize=${resizeOptions}`
    },
    onChangeLanguage() {
      this.formatPieLabels()
      this.reloadAreasTable = true
      this.$nextTick(() => {
        // NOTE: Forces reload of <areas-table> content
        this.reloadAreasTable = false
      })
    },
    onChangeLoading(loading) {
      // console.warn('TrafficSection - loading-state event =', loading)
      if (loading === true) {
        this.heatmapChartSelector = []
        this.heatmapChartSelector2 = []
      }
    },
    getDataForAreas(data, totalData, perHour = false) {
      let formattedData = []
      if (totalData) {
        formattedData.push({
          label: TranslateService.get(totalData.label),
          value: totalData.value
        })
      }
      const pois = _.get(data, 'pois', false)
      const vehicles = _.get(data, 'vehicles', false)
      _.each(_.get(data, 'abdAreas', []), area => {
        const poi = this.findByAreaId(pois, area.id)
        const vehicle = this.findByAreaId(vehicles, area.id)
        const visualIdentifier = this.getVisualIdentifier(poi, vehicle)
        formattedData.push({
          visualIdentifier,
          label: this.getAreaFullName(data, 'id', area.id),
          value: _.sum(this.getAreaPerDay(data, perHour, area._id))
        })
      })

      return _.sortBy(formattedData, ['visualIdentifier'], ['desc'])
    },
    getVehicleName(area, vehicle, noSeries = false) {
      if (!_.get(vehicle, 'nameplate', false)) {
        return area.abdName
      }
      let nameParts = noSeries ? ['nameplate', 'color'] : ['nameplate', 'series', 'color']
      return _.join(
        _.compact(
          _.map(nameParts, key => {
            const val = _.get(vehicle, key, false)
            return val ? TranslateService.get(val) : false
          })
        ),
        ' - '
      )
    },
    getAreaName(area, poi, vehicle, noSeries = false) {
      if (_.get(poi, 'name', false)) {
        return TranslateService.get(poi.name)
      }
      return this.getVehicleName(area, vehicle, noSeries)
    },
    getAreaFullName(data, key, areaId, noSeries = false, mobileMode = false) {
      const area = _.find(
        _.get(this.primaryData, 'abdAreas', []),
        area => _.get(area, key, false) === areaId
      )
      if (_.isUndefined(area)) {
        return ''
      }
      const vehicle = this.findByAreaId(_.get(data, 'vehicles', []), area.id)
      const poi = this.findByAreaId(_.get(data, 'pois', []), area.id)
      const nameParts = []
      nameParts.push(this.getVisualIdentifier(poi, vehicle))
      if (!mobileMode) {
        nameParts.push(this.getAreaName(area, poi, vehicle, noSeries))
      }
      return _.join(nameParts, ' - ')
    },
    getDataForAttractiveness(data, totalData = false) {
      let formattedData = []
      _.each(_.get(data, 'abdAreas', []), area => {
        formattedData.push({
          label: this.getAreaFullName(data, 'id', area.id),
          _id: area._id,
          value: _.sum(this.getAttractivenessPerArea(data, area._id))
        })
      })
      formattedData = _.orderBy(formattedData, ['value'], ['desc'])
      if (totalData) {
        return _.concat(
          [
            {
              label: TranslateService.get(totalData.label),
              value: totalData.value
            }
          ],
          formattedData
        )
      }
      return formattedData
    },
    formatStayAverage(value) {
      const tmp = _.isObject(value) ? _.get(value, 'value', 0) : value
      return _.isString(tmp) ? tmp : this.msToTime(tmp)
    },
    getAbdTrafficForDate(data) {
      const toFind = this.labelToDate(this.selectedStayAverageDate)
      return _.find(_.get(data, 'abdTraffic', []), { date: toFind })
    },
    getSelectedAreaDate(data) {
      return _.get(_.map(_.get(data, 'abdByDate', []), 'date'), `[${this.highlightedAreaDate}]`, 0)
    },
    getStayAverage(perHour = false, filterByArea = false, data = false, forPdf = false) {
      if (!data) {
        data = this.primaryData
      }
      if (filterByArea !== false) {
        let formattedData = false
        if (this.selectedAreaHour !== 0) {
          formattedData = _.filter(data.abdTrafficAreaHours, { time: this.selectedAreaHour })
        } else {
          formattedData = data.abdTrafficAreas
        }
        if (this.selectedArea !== 0) {
          formattedData = _.filter(formattedData, {
            area: forPdf ? filterByArea : this.selectedArea
          })
        }
        if (this.selectedAreaDate !== 0) {
          let date = false
          if (this.selectedAreaDate.indexOf('/') !== -1) {
            date = moment(this.selectedAreaDate, 'YYYY/MM/DD').format('YYYY-MM-DD')
          } else {
            date = this.getSelectedAreaDate(data)
          }
          formattedData = _.filter(formattedData, { date: date })
        }
        return _.mean(_.compact(_.map(formattedData, 'stayAverage')))
      }
      if (this.highlightedStayAverage !== 0 && perHour) {
        return _.get(this.getAbdTrafficForDate(data), 'stayAverage', 0)
      }
      return this.formatStayAverage(
        _.mean(_.compact(_.map(_.get(data, 'abdTraffic', []), 'stayAverage')))
      )
    },
    updateSelectedValue(barChart, selectedValue, highlightedValue, specificUpdate = false) {
      _.set(
        this,
        selectedValue,
        _.get(barChart, `data.chartSelectorLabels[${highlightedValue - 1}]`, 0)
      )
      this.gotData(specificUpdate || true)
    },
    displayFullscreenImage(url) {
      FullscreenViewerService.events.emit('displayViewer', url, true)
    },
    getSelectedCamera() {
      return this.selectedCameraDevice
        ? _.get(this.cameras, `[${this.selectedCameraDevice}]`, {})
        : _.first(this.cameras)
    },
    getCameraImage() {
      return _.get(
        this.getSelectedCamera(),
        `byDate[${this.selectedCameraDay}].byHour[${this.selectedCameraHour}].url`,
        false
      )
    },
    getDataForCameras(type) {
      if (type === 'devices') {
        return this.cameras
      }
      const selectedCamera = this.getSelectedCamera()
      if (type === 'days') {
        return _.map(_.get(selectedCamera, 'byDate'), date => {
          return {
            label: date.date
          }
        })
      }
      return _.map(_.get(selectedCamera, `byDate[${this.selectedCameraDay}].byHour`), hour => {
        return {
          label: moment(hour.time, 'HH:mm:ss').format('HH:mm')
        }
      })
    },

    isPictureInHoursRange(date, endDate, startingHour, endingHour, lastDayEndHour, picture) {
      if (date === endDate) {
        const time = moment.duration(picture.time).as('milliseconds')
        if (!time || time >= lastDayEndHour) {
          return false
        }
        return true
      }
      let currentHour = _.toNumber(_.get(_.split(picture.time, ':'), '[0]', 9))
      return currentHour > endingHour || currentHour < startingHour ? false : picture
    },
    getVehicleFullName(vehicle) {
      return _.join(
        _.compact(
          _.map(['nameplate', 'series', 'color'], key => {
            const val = _.get(vehicle, key, false)
            return val ? TranslateService.get(val) : false
          })
        ),
        ' - '
      )
    },
    findByAreaId(data, areaId) {
      return _.find(data, item => item.abd.areaId === areaId)
    },
    getVisualIdentifier(poi, vehicle) {
      return _.get(poi, 'visualIdentifier', _.get(vehicle, 'visualIdentifier', ''))
    },
    getCameras() {
      if (!_.get(this.primaryData, 'camera360', false)) {
        console.info(`Event has no cameras`)
        return
      }
      let cameras = [this.primaryData.camera360]
      cameras[0].label = TranslateService.get('T_CAMERA_360')
      const areas = _.get(this.primaryData, 'abdAreas', [])
      const pois = _.get(this.primaryData, 'pois', [])
      const vehicles = _.get(this.primaryData, 'vehicles', [])
      _.each(_.get(this.primaryData, 'cameras', false), camera => {
        const area = _.find(areas, area => _.includes(area.devices, camera.deviceCode))
        if (_.isUndefined(area)) {
          console.error(`No area found for device ${camera.deviceCode}`)
        } else {
          const poi = this.findByAreaId(pois, area.id)
          const vehicle = this.findByAreaId(vehicles, area.id)
          const visualIdentifier = this.getVisualIdentifier(poi, vehicle)
          let name = _.get(area, 'abdName', '')
          if (!_.isUndefined(vehicle)) {
            name = this.getVehicleFullName(vehicle)
          } else if (!_.isUndefined(poi)) {
            name = TranslateService.get(poi.name)
          }
          cameras.push(
            _.merge(camera, { visualIdentifier, label: `${visualIdentifier} - ${name}` })
          )
        }
      })
      const hoursRange = _.get(this.primaryData, 'pavilion.event.hoursRange', [])
      const endDate = _.get(this.primaryData, 'pavilion.event.endDate', false)
      let lastDayEndHour = _.get(this.primaryData, 'pavilion.event.lastDayEndHour', '18:59:59')
      lastDayEndHour = moment.duration(lastDayEndHour).as('milliseconds')
      const startingHour = _.toNumber(_.get(_.split(_.first(hoursRange), ':'), '[0]', 9))
      const endingHour = _.toNumber(_.get(_.split(_.last(hoursRange), ':'), '[0]', 9))
      cameras = _.map(cameras, camera => {
        camera.byDate = _.map(camera.byDate, date => {
          date.byHour = _.compact(
            _.map(date.byHour, picture =>
              this.isPictureInHoursRange(
                date,
                endDate,
                startingHour,
                endingHour,
                lastDayEndHour,
                picture
              )
            )
          )
          return date
        })
        return camera
      })
      cameras = _.sortBy(cameras, 'visualIdentifier')
      cameras.unshift(cameras.pop())
      return cameras
    },
    filterIfNoDataPerHour(cardTopData) {
      if (_.sum(_.map(_.get(this.primaryData, 'abdTrafficHours', []), 'count')) === 0) {
        cardTopData.type = 'hidden'
        console.info(`Traffic per hour hidden since no data`)
      }
      return cardTopData
    },
    getAverageForArea(data) {
      let filteredData = this.filterBy(_.get(data, 'abdTrafficAreaHours', []), this.areaMappings)
      filteredData = _.map(_.groupBy(filteredData, 'time'), timeItems =>
        _.sum(_.map(timeItems, 'count'))
      )
      return {
        label: TranslateService.get('T_AVERAGE_PER_HOUR'),
        value: this.getAverage(filteredData)
      }
    },
    getAveragePerHour(data, field) {
      return {
        label: TranslateService.get('T_AVERAGE_PER_HOUR'),
        value: this.getAverage(_.map(data, field))
      }
    },
    getTotalTrafficByHour(stayAverage = false) {
      const hour = _.get(this, `selected${stayAverage ? 'StayAverage' : ''}Hour`, 0)
      const date = _.get(this, `selected${stayAverage ? 'StayAverage' : ''}Date`, 0)
      const highlightedHour = stayAverage
        ? this.highlightedStayAverageHour
        : this.highlightedTrafficHourValue
      const field = stayAverage ? 'stayAverage' : 'count'
      if (hour === 0) {
        if (date === 0) {
          return this.getAveragePerHour(_.get(this.primaryData, 'abdTrafficHours', []), field)
        }
        const hoursForSelectedDate = _.filter(this.primaryData.abdTrafficHours, {
          date: moment(date, 'YYYY/MM/DD').format('YYYY-MM-DD')
        })
        return this.getAveragePerHour(hoursForSelectedDate, field)
      }
      return {
        label: date,
        value: _.get(this.primaryData, `abdTrafficHours[${highlightedHour - 1}].${field}`, 0)
      }
    },
    formatPieLabels() {
      if (!this.primaryData) {
        return
      }
      _.set(this.pie, 'data.labels', this.getPieLabels(this.primaryData))
      _.set(this.pieGenders, 'data.labels', this.getPieLabels(this.primaryData, 'gender'))
      this.cleanPieLabels(this.pieGenders)
      _.set(this.pieAgeRanges, 'data.labels', this.getPieLabels(this.primaryData, 'age'))
      this.cleanPieLabels(this.pieAgeRanges)
      if (this.secondaryData) {
        _.set(this.pie2, 'data.labels', this.getPieLabels(this.secondaryData))
        _.set(this.pieGenders2, 'data.labels', this.getPieLabels(this.secondaryData, 'gender'))
        this.cleanPieLabels(this.pieGenders2)
        _.set(this.pieAgeRanges2, 'data.labels', this.getPieLabels(this.secondaryData, 'age'))
        this.cleanPieLabels(this.pieAgeRanges2)
      }
    },
    cleanPieLabels(pieChart) {
      const indexes = []
      _.each(_.get(pieChart, 'data.datasets[0].data', []), (d, i) => {
        if (d !== 0) {
          const label = _.get(pieChart, `data.labels[${i}]`, false)
          if (label) {
            indexes.push(TranslateService.get(`T_${label}`))
          }
        }
      })
      _.set(pieChart, 'data.datasets[0].index', indexes)
    },
    getPieLabels(data, demographyField = false) {
      if (demographyField) {
        let keys = _.keys(_.get(data, `abdTraffic[0].demography.${demographyField}`, {}))
        if (_.isEmpty(keys) && demographyField === 'gender') {
          return ['female', 'male']
        }
        return keys
      }
      return _.map(_.get(data, 'trafficByVehicle', []), (item, i) => {
        return `${i + 1} - ${TranslateService.get(_.get(item, `vehicle.nameplate`, ''))}`
      })
    },
    seriesNonEmpty(vehicles) {
      return _.get(_.trim(_.join(_.compact(_.map(vehicles, 'series')), '')), 'length', 0) !== 0
    },
    displayFullscreenNeighbors(data) {
      FullscreenViewerService.events.emit(
        'displayViewer',
        _.get(data, 'pavilion.hallView', ''),
        true
      )
    },
    resetChartsHighlights() {
      this.highlightedTrafficValue = 0
      this.highlightedTrafficHourValue = 0
      // this.highlightedStayAverage = 0
      // this.highlightedStayAverageHour = 0
      // this.highlightedArea = 0
      // this.highlightedAreaDate = 0
      // this.highlightedAreaHour = 0
      this.$forceUpdate()
    },
    getIcon(icon) {
      return require(`../assets/icons/${icon}.svg`)
    },
    getPiesSuffixes(partialUpdate, forPdf) {
      if (forPdf) {
        return [forPdf === 'traffic' ? 'StayAverage' : 'PerArea']
      }
      return !_.isString(partialUpdate)
        ? ['StayAverage', 'PerArea']
        : [partialUpdate === 'Area' ? 'PerArea' : partialUpdate]
    },
    getPieAgeRangesIndexes(pie, pieData, indexes) {
      let newMappings = ''
      if (_.get(_.compact(indexes), 'length', 1) === 1) {
        const foundIndex = _.findIndex(indexes, percentage => percentage !== false)
        const foundMapping = _.get(this.indexesMapping, `[${foundIndex}]`, {})
        if (foundIndex == 0) {
          newMappings = 'lessthan19'
        } else if (foundIndex === _.get(indexes, 'length', 1) - 1) {
          newMappings = 'morethan55'
        } else {
          newMappings = `${foundMapping.from}to${foundMapping.to}`
        }
        newMappings = _.split(newMappings, ',')
        _.set(pie, 'data.indexes', newMappings)
        return
      }
      newMappings = 'lessThan'
      _.each(indexes, (percentage, i) => {
        if (percentage) {
          if (indexes[i - 1]) {
            newMappings += ','
            newMappings += this.indexesMapping[i].from
          } else {
            if (newMappings.indexOf('to') !== -1) {
              newMappings += 'to'
            }
            if (i === _.get(indexes, 'length', 1) - 1) {
              newMappings += ','
              newMappings += this.indexesMapping[i].from
            } else {
              newMappings += this.indexesMapping[i].to + 1
            }
          }
        }
      })
      newMappings = _.split(newMappings, ',')
      newMappings[newMappings.length - 1] = `moreThan${newMappings[newMappings.length - 1] - 1}`
      _.set(pie, 'data.indexes', newMappings)
    },
    getPiePercentages(pie, forPdf = false, forAgeRanges = false) {
      const pieData = _.get(pie, 'data.datasets[0].data', [])
      const total = _.sum(pieData)
      const indexes = _.map(pieData, val => {
        const percentage = Math.round((val / total) * 100)
        return percentage === 0 ? false : percentage
      })
      if (forAgeRanges) {
        this.getPieAgeRangesIndexes(pie, pieData, indexes)
      }
      if (forPdf) {
        _.set(
          pie,
          'data.datasets[0].index',
          _.map(_.compact(indexes), percentage => this.formatPercentage(percentage))
        )
      }
      _.set(
        pie,
        'data.datasets[0].data',
        _.compact(_.map(pieData, (d, i) => total * (indexes[i] / 100)))
      )
    },
    refreshPies(partialUpdate = false, forPdf = false) {
      const pieSuffixes = this.getPiesSuffixes(partialUpdate, forPdf)
      _.each(pieSuffixes, suffix => {
        const pieSuffix = suffix === 'PerArea' ? 'PerArea' : ''
        const piesToGenerate = forPdf
          ? [`pieGenders`, `pieAgeRanges`]
          : [`pieGenders`, `pieAgeRanges`, `pieGendersByHour`, `pieAgeRangesByHour`]
        _.each(piesToGenerate, pie => {
          const perHour = _.includes(pie, 'Hour')
          const field = _.includes(pie, 'Age') ? 'age' : 'gender'
          _.each(_.compact([this.primaryData, this.secondaryData]), (data, index) => {
            const highlightedField = `highlighted${suffix === 'PerArea' ? 'Area' : suffix}${
              perHour ? 'Hour' : ''
            }`
            const pieName = `${pie}${pieSuffix}${index === 1 ? '2' : ''}`
            const isArea = suffix === 'PerArea'
            const isAgeRanges = pieName.indexOf('AgeRanges') !== -1
            if (!isAgeRanges || (isAgeRanges && !_.get(data, 'pavilion.hideAgeRanges', false))) {
              _.set(this, pieName, this.getPieData(data, highlightedField, field, isArea))
              this.getPiePercentages(
                _.get(this, pieName, {}),
                forPdf,
                pie.indexOf('AgeRanges') !== -1
              )
            }
          })
        })
      })
    },
    getBubbleChartBackground(data) {
      return _.get(data, 'pavilion.gaImage') || _.get(data, 'abdShow.map')
    },
    isPointInArea(point, area) {
      return (
        point.x >= area.rect.x &&
        point.x <= area.rect.x + area.rect.width &&
        point.y >= area.rect.y &&
        point.y <= area.rect.y + area.rect.height
      )
    },
    playPauseHeatmap(forSecondary = false) {
      const isPlayingHeatmapKey = `isPlayingHeatmap${forSecondary ? '2' : ''}`
      const playHeatmapIntervalKey = `playHeatmapInterval${forSecondary ? '2' : ''}`
      this[isPlayingHeatmapKey] = !this[isPlayingHeatmapKey]
      clearInterval(this[playHeatmapIntervalKey])
      if (this[isPlayingHeatmapKey]) {
        this[playHeatmapIntervalKey] = setInterval(() => {
          this.moveToNextHeatmapDay(forSecondary)
        }, 1500)
        this.moveToNextHeatmapDay(forSecondary)
      }
      return true
    },
    playPauseHeatmap2() {
      this.playPauseHeatmap(true)
    },
    moveToNextHeatmapDay(forSecondary = false) {
      const heatmapPerDayKey = `heatmapPerDay${forSecondary ? '2' : ''}`
      const highlightedHeatmapDayKey = `highlightedHeatmapDay${forSecondary ? '2' : ''}`
      if (
        _.get(this[heatmapPerDayKey], `[${this[highlightedHeatmapDayKey] + 1 - 1}].date`, 0) !== 0
      ) {
        this[highlightedHeatmapDayKey] += 1
      } else {
        this[highlightedHeatmapDayKey] = 0
      }
    },
    playPauseBubble(forSecondary = false) {
      const isPlayingBubbleKey = `isPlayingBubble${forSecondary ? '2' : ''}`
      const playBubbleIntervalKey = `playBubbleInterval${forSecondary ? '2' : ''}`
      this[isPlayingBubbleKey] = !this[isPlayingBubbleKey]
      clearInterval(this[playBubbleIntervalKey])
      if (this[isPlayingBubbleKey]) {
        this[playBubbleIntervalKey] = setInterval(() => {
          this.moveToNextBubbleDay(forSecondary)
        }, 1500)
        this.moveToNextBubbleDay(forSecondary)
      }
      return true
    },
    playPauseBubble2() {
      this.playPauseBubble(true)
    },
    moveToNextBubbleDay(forSecondary = false) {
      const heatmapPerDayKey = `heatmapPerDay${forSecondary ? '2' : ''}`
      const highlightedBubbleDayKey = `highlightedBubbleDay${forSecondary ? '2' : ''}`
      if (
        _.get(this[heatmapPerDayKey], `[${this[highlightedBubbleDayKey] + 1 - 1}].date`, 0) !== 0
      ) {
        this[highlightedBubbleDayKey] += 1
      } else {
        this[highlightedBubbleDayKey] = 0
      }
    },
    getHeatmapForSelectedDay(forSecondary = false) {
      const requestingKey = `requestingHeatmapData${forSecondary ? '2' : ''}`
      const heatmapPerDayKey = `heatmapPerDay${forSecondary ? '2' : ''}`
      const selectedHeatmapDayKey = `selectedHeatmapDay${forSecondary ? '2' : ''}`
      const heatmapForSelectedDayKey = `heatmapForSelectedDay${forSecondary ? '2' : ''}`
      if (this[requestingKey] || !this[heatmapPerDayKey]) {
        return false
      }
      const foundDataForSelectedDay = _.find(this[heatmapPerDayKey], {
        date: this[selectedHeatmapDayKey]
      })
      if (_.isUndefined(foundDataForSelectedDay)) {
        console.info(`No heatmap data found for date ${this[selectedHeatmapDayKey]}`)
        this[heatmapForSelectedDayKey] = false
        this.reloadHeatmap(forSecondary)
        return false
      }
      this[heatmapForSelectedDayKey] = {
        heatmapPointsListMax: _.max(_.map(_.get(foundDataForSelectedDay, 'points', []), 'count')),
        heatmapPointsListMin: _.min(_.map(_.get(foundDataForSelectedDay, 'points', []), 'count')),
        heatmapPointsList: _.get(foundDataForSelectedDay, 'points', [])
      }
      // console.warn(`Heatmap per day`, this[heatmapForSelectedDayKey])
      this.reloadHeatmap(forSecondary)
      return this[heatmapForSelectedDayKey]
    },
    getBubbleForSelectedDay(forSecondary = false) {
      const requestingKey = `requestingBubbleData${forSecondary ? '2' : ''}`
      const dataKey = `${forSecondary ? 'secondary' : 'primary'}Data`
      const bubbleForSelectedDayKey = `bubbleForSelectedDay${forSecondary ? '2' : ''}`
      if (this[requestingKey]) {
        return false
      }
      this[bubbleForSelectedDayKey] = this.getBubbleChart(this[dataKey])
      this.reloadBubble(forSecondary)
      return this[bubbleForSelectedDayKey]
    },
    reloadHeatmap(forSecondary = false) {
      const reloadingHeatmapKey = `reloadingHeatmap${forSecondary ? '2' : ''}`
      this[reloadingHeatmapKey] = true
      this.$forceUpdate()
      this.$nextTick(() => {
        this[reloadingHeatmapKey] = false
      })
    },
    reloadBubble(forSecondary = false) {
      const reloadingBubbleKey = `reloadingBubble${forSecondary ? '2' : ''}`
      this[reloadingBubbleKey] = true
      this.$forceUpdate()
      this.$nextTick(() => {
        this[reloadingBubbleKey] = false
      })
    },
    async getHeatmapPerDay(forSecondary = false) {
      let requestingKey = `requestingHeatmapData${forSecondary ? '2' : ''}`
      let heatmapDataKey = `heatmapPerDay${forSecondary ? '2' : ''}`
      this.heatmapChartSelector = []
      this.heatmapChartSelector2 = []
      let dataKey = `${forSecondary ? 'secondary' : 'primary'}Data`
      if (this[requestingKey]) {
        return
      }
      this[heatmapDataKey] = false
      this[requestingKey] = true
      try {
        this[heatmapDataKey] = await AnalyticDataService.getHeatmapPerDay(
          this[dataKey].pavilion,
          this[dataKey].dates
        )
        this.getHeatmapForSelectedDay(forSecondary)
        if (forSecondary) {
          this.heatmapChartSelector2 = this.getDataForHeatmap(true)
        } else {
          this.heatmapChartSelector = this.getDataForHeatmap()
        }
      } catch (error) {
        console.error(`getHeatmapPerDay - Error:`, error)
      }
      this[requestingKey] = false
    },
    async getBubblePerDay(forSecondary = false) {
      let requestingKey = `requestingBubbleData${forSecondary ? '2' : ''}`
      let bubbleDataKey = `bubblePerDay${forSecondary ? '2' : ''}`
      if (this[requestingKey]) {
        return
      }
      this[bubbleDataKey] = false
      this[requestingKey] = true
      try {
        this[bubbleDataKey] = this.getBubbleChart(
          forSecondary ? this.secondaryData : this.primaryData
        )
        this.getBubbleForSelectedDay(forSecondary)
      } catch (error) {
        console.error(`getBubblePerDay - Error:`, error)
      }
      this[requestingKey] = false
    },
    getDataForHeatmap(forSecondary = false) {
      const heatmapPerDayKey = `heatmapPerDay${forSecondary ? '2' : ''}`
      if (!this[heatmapPerDayKey]) {
        return []
      }
      const allDays = {
        label: TranslateService.get('T_ALL_DAYS'),
        value: _.sum(_.map(this[heatmapPerDayKey], item => _.sum(_.map(item.points, 'count'))))
      }
      // console.warn(`this[heatmapPerDayKey]`, this[heatmapPerDayKey])
      const formattedData = []
      _.each(this[heatmapPerDayKey], item => {
        const total = _.sum(_.map(item.points, 'count'))
        formattedData.push({
          label: item.date,
          total,
          value: (total / allDays.value) * 100
        })
      })
      return _.concat([allDays], formattedData)
    },
    groupAttractivenessByArea(data) {
      let areas = _.get(data, 'abdAreas', [])
      const vehicles = _.get(data, 'vehicles', [])
      const pois = _.get(data, 'pois', [])
      const { trafficForAreas, totalUniqueVisitors } = this.getTotalUniqueVisitors(data)
      const chartData = _.map(areas, area => {
        const poi = this.findByAreaId(pois, area.id)
        const vehicle = this.findByAreaId(vehicles, area.id)
        let label = _.get(area, 'abdName', '')
        if (!_.isUndefined(vehicle)) {
          label = TranslateService.get(vehicle.nameplate)
        } else if (!_.isUndefined(poi)) {
          label = TranslateService.get(poi.name)
        }
        const visualIdentifier = this.getVisualIdentifier(poi, vehicle)
        const total = _.sum(_.map(_.filter(trafficForAreas, { area: area._id }), 'count'))
        const percentageValue = total / totalUniqueVisitors
        const percentage = Math.round((total / totalUniqueVisitors) * 100 * 100) / 100
        const result = {
          area: area._id,
          areaId: area.id,
          label: `${visualIdentifier} - ${label}\n${percentage}%`,
          x: area.rect.x + Math.floor(area.rect.width / 2),
          y: area.rect.y + Math.floor(area.rect.height / 2),
          count: percentage,
          percentageValue,
          r: percentage
        }
        return result
      })
      // console.warn(`chart data per area:`, chartData)
      return _.orderBy(chartData, ['percentageValue'], ['desc'])
    },
    getBubbleChart(data) {
      return {
        data: {
          datasets: [
            {
              datasetIndex: 0,
              label: ['Heatmap label'],
              data: this.groupAttractivenessByArea(data)
            }
          ]
        }
      }
    },
    getBarAttractiveness() {
      const barAttractiveness = this.getBarData('highlightedAttractiveness')
      _.each(_.get(barAttractiveness, 'data.datasets', []), (dataset, i) => {
        const labels = []
        let orderedData = _.map(_.get(dataset, 'data', []), (data, i) => {
          return {
            before: i,
            data: data
          }
        })
        if (this.highlightedAttractiveness === 0) {
          orderedData = _.orderBy(orderedData, ['data'], ['desc'])
        }
        _.set(barAttractiveness, `data.datasets[${i}].data`, _.map(orderedData, 'data'))
        if (!this.secondaryData || !_.get(barAttractiveness, 'data.labels', false)) {
          _.each(orderedData, data => {
            labels.push(_.get(barAttractiveness, `data.labels[${data.before}]`, ''))
          })
          _.set(barAttractiveness, 'data.labels', labels)
        }
      })
      this.barAttractiveness = barAttractiveness
    },
    skipCalculationsForDesktop() {
      if (!this.desktopMode) {
        return false
      }
      if (this.desktopMode === 'traffic-section-root') {
        return true
      }
      if (!this.primaryData || (this.desktopMode === 'compare' && !this.secondaryData)) {
        console.log(`Desktop mode - ${this.desktopMode} - no data received, will skip calculations`)
        return true
      }
      return false
    },
    formatDesktopTitle(subSection, fullWidth = false) {
      return {
        id: subSection.id,
        title: subSection.title,
        fullWidth,
        cardTop: subSection.cardTop
      }
    },
    formatFilteredSubSections() {
      _.each(this.filteredSubSections, subSection => {
        if (subSection.id === 'stand-traffic') {
          subSection.desktopTitle.fullWidth =
            this.hasStayAverage(this.primaryData) || this.secondaryData
        }
      })
      this.desktopTitles = []
      let titlesForLine = []
      _.each(this.filteredSubSections, subSection => {
        if (_.get(subSection, 'desktopTitle.fullWidth', false)) {
          if (titlesForLine.length === 1) {
            titlesForLine[0].fullWidth = true
            this.desktopTitles.push(titlesForLine)
          }
          titlesForLine = []
          if (subSection.id === 'stand-traffic') {
            const foundIndex = _.findIndex(this.filteredSubSections, { id: 'stand-statistics' })
            if (foundIndex !== -1) {
              this.filteredSubSections[foundIndex].hidden = true
              this.desktopTitles.push([
                this.formatDesktopTitle(subSection, true),
                this.filteredSubSections[foundIndex]
              ])
            } else {
              this.desktopTitles.push([this.formatDesktopTitle(subSection, true)])
            }
          } else {
            this.desktopTitles.push([this.formatDesktopTitle(subSection, true)])
          }
        } else if (!subSection.hidden) {
          const title = this.formatDesktopTitle(subSection)
          titlesForLine.push(title)
          if (titlesForLine.length >= 2) {
            this.desktopTitles.push(titlesForLine)
            titlesForLine = []
          }
        }
      })
      this.desktopLayoutReady = true
      this.$forceUpdate()
    },
    getAreaOrderedIds(data) {
      const pois = _.get(data, 'pois', [])
      const vehicles = _.get(data, 'vehicles', [])
      return _.map(
        _.orderBy(
          _.map(
            _.get(data, 'abdAreas', []),
            area => {
              const poi = this.findByAreaId(pois, area.id)
              const vehicle = this.findByAreaId(vehicles, area.id)
              return {
                _id: area._id,
                vi: this.getVisualIdentifier(poi, vehicle)
              }
            },
            '_id'
          ),
          ['vi'],
          ['asc']
        ),
        '_id'
      )
    },
    async gotData(partialUpdate = false) {
      if (this.desktopMode && this.skipCalculationsForDesktop()) {
        // console.warn(`${this.desktopMode} - ${partialUpdate}`)
        return
      }
      if (!partialUpdate) {
        this.$nextTick(() => {
          this.filterSubSections(this.primaryData)
          if (this.desktopMode) {
            this.formatFilteredSubSections()
          }
          this.$forceUpdate()
        })
      }
      this.heatmapChartSelector = []
      this.heatmapChartSelector2 = []
      this.heatmapChartSelector = this.getDataForHeatmap()
      if (this.secondaryData) {
        this.heatmapChartSelector2 = this.getDataForHeatmap(true)
      }
      this.areas = this.getAreaOrderedIds(this.primaryData)
      this.areas2 = this.getAreaOrderedIds(this.secondaryData)
      if (this.pdfPage) {
        if (this.pdfPage === 'traffic-4') {
          this.barStayAverage = this.getBarData('highlightedStayAverage')
          this.barStayAverageHour = this.getBarData('highlightedStayAverageHour')
        } else if (this.pdfPage === 'traffic-3') {
          this.bubbleChart = this.getBubbleChart(this.primaryData)
          this.getBarAttractiveness()
        } else {
          this.barArea = this.getBarData('highlightedArea')
          this.barAreaHour = this.getBarData('highlightedAreaHour')
        }
        this.refreshPies(partialUpdate, this.pdfPage)
        return process.nextTick(() => {
          this.$forceUpdate()
        })
      }
      if (!partialUpdate || partialUpdate === 'StayAverage') {
        this.barStayAverage = this.getBarData('highlightedStayAverage')
        this.barStayAverageHour = this.getBarData('highlightedStayAverageHour')
      }
      if (!partialUpdate || partialUpdate === 'Area') {
        this.barArea = this.getBarData('highlightedArea')
        this.barAreaHour = this.getBarData('highlightedAreaHour')
      }
      if (!partialUpdate || partialUpdate === true) {
        this.bar = this.getBarData('highlightedTrafficValue')
        this.barHour = this.getBarData('highlightedTrafficHourValue')
      }
      if (!partialUpdate || partialUpdate === 'Attractiveness') {
        this.bubbleChart = this.getBubbleChart(this.primaryData)
        if (this.secondaryData) {
          this.bubbleChart2 = this.getBubbleChart(this.secondaryData)
        }
        this.getBarAttractiveness()
      }
      if (!this.secondaryData && this.selectedArea === 0) {
        this.selectedArea = _.first(this.areas)
      }
      this.refreshPies(partialUpdate)
      if (partialUpdate) {
        return
      }
      this.getHeatmapPerDay()
      this.getBubblePerDay()
      if (this.secondaryData) {
        this.getHeatmapPerDay(true)
        this.getBubblePerDay(true)
      }
      // console.warn(`this.primaryData = `, this.primaryData)
      process.nextTick(() => {
        this.pie = this.getPieData(this.primaryData, 'highlightedNameplate')
        if (this.secondaryData) {
          this.pie2 = this.getPieData(this.secondaryData, 'highlightedNameplate2')
          this.$forceUpdate()
        } else {
          this.cameras = this.getCameras()
          this.getClosestCameraImage()
        }
        this.$forceUpdate()
      })
    },
    getClosestCameraImage() {
      this.initCameras = true
      if (!this.showOngoing()) {
        this.initCameras = false
        return
      }
      const currentDate = moment().format('YYYY-MM-DD')
      let currentHour = _.toNumber(moment().format('HH'))
      const hoursRange = _.get(this.primaryData, 'pavilion.event.hoursRange', [])
      const startingHour = _.toNumber(_.get(_.split(_.first(hoursRange), ':'), '[0]', 9))
      const endingHour = _.toNumber(_.get(_.split(_.last(hoursRange), ':'), '[0]', 9))
      currentHour =
        currentHour > endingHour || currentHour < startingHour ? `9:00` : `${currentHour}:00`
      // console.warn(
      //   `showOngoing`,
      //   this.selectedCameraDay,
      //   currentDate,
      //   currentHour,
      //   this.selectedCameraHour
      // )
      let foundHour = false
      _.each(this.cameras, (camera, indexCamera) => {
        const foundDay = _.find(_.get(camera, 'byDate', []), { date: currentDate })
        if (!_.isUndefined(foundDay) && _.get(foundDay, `byHour.length`, 0) !== 0) {
          this.selectedCameraDay = _.findIndex(_.get(camera, 'byDate', []), { date: currentDate })
          const hoursOfDay = _.get(foundDay, `byHour`, [])
          foundHour = _.findIndex(hoursOfDay, {
            time: currentHour
          })
          if (foundHour !== -1) {
            this.selectedCameraDevice = indexCamera
            this.selectedCameraHour = foundHour
          } else {
            foundHour = _.get(hoursOfDay, 'length', 1) - 1
            this.selectedCameraDevice = indexCamera
            this.selectedCameraHour = foundHour
          }
          return false
        }
      })
      if (!foundHour) {
        this.selectedCameraDevice = 0
        this.selectedCameraDay = 0
        this.selectedCameraHour = 0
        this.$forceUpdate()
      }
      this.$nextTick(() => {
        this.initCameras = false
      })
    },
    showOngoing() {
      const now = moment().startOf('day')
      const daysUntilStartOfShow = moment
        .duration(now.diff(moment(_.first(this.primaryData.dates)).startOf('day')))
        .asDays()
      const daysUntilEndOfShow = moment
        .duration(now.diff(moment(_.last(this.primaryData.dates)).startOf('day')))
        .asDays()
      if (daysUntilStartOfShow >= 0 && daysUntilEndOfShow <= 0) {
        return true
      }
      return false
    },
    getWeatherColors(data) {
      return _.map(_.get(data, 'weather', []), d =>
        _.get(this.weatherColorMap, `[${d.status}]`, false)
      )
    },
    getSelectedArea() {
      return this.selectedArea !== 0 ? this.selectedArea : _.first(this.areas)
      // console.warn(
      //   `GET SELECTED AREA = ${this.selectedArea}`,
      //   _.get(_.find(this.primaryData.abdAreas, { _id: test }), 'abdName', false)
      // )
    },
    getTotalForSelectedArea(data, selectedHour = false, forceArea = 0, forPdf = false) {
      if (!forPdf && !_.isNumber(forceArea)) {
        if (!selectedHour) {
          return ''
        }
        let areasPerDay = _.get(data, 'abdTrafficAreas', [])
        if (forceArea) {
          areasPerDay = _.filter(areasPerDay, { area: forceArea })
        }
        return _.sum(_.map(areasPerDay, 'count'))
      }
      const test = forPdf ? forceArea : forceArea === 0 ? 0 : forceArea - 1
      return _.sum(this.getAreaPerDay(data, selectedHour, test, true))
    },
    getTotalForSelectedAttractiveness(data) {
      let test = this.getAttractivenessPerDay(data)
      if (_.get(test, `[0].count`, false)) {
        test = _.map(test, 'count')
      }
      return _.sum(test)
    },
    getAreaPerHour(data) {
      let formattedData = _.cloneDeep(_.get(data, 'abdTrafficAreaHours', []))
      if (!this.secondaryData && this.selectedArea === 0) {
        this.selectedArea = _.first(this.areas)
      }
      formattedData = _.groupBy(this.filterBy(formattedData, this.areaMappings, false), 'time')
      const hoursRange = _.get(data, 'pavilion.event.hoursRange', [])
      formattedData = _.filter(formattedData, (records, hour) => _.includes(hoursRange, hour))
      return _.map(formattedData, day => _.sum(_.map(day, 'count')))
    },
    getAreaPerDay(data, selectedHour = false, forceArea = 0, forPdf = false) {
      if (selectedHour !== false) {
        return this.getAreaPerHour(data)
      }
      let areaToFind = forceArea || this.selectedArea || (this.pdfArea === false ? 0 : this.pdfArea)
      if (forPdf) {
        areaToFind = forceArea
      }
      if (areaToFind === 0) {
        areaToFind = _.get(_.first(data.abdAreas), '_id', 0)
      }
      let areasPerDay = _.get(data, 'abdTrafficAreas', [])
      if (areaToFind) {
        areasPerDay = _.filter(areasPerDay, { area: areaToFind })
      }
      return _.map(areasPerDay, 'count')
    },
    getAttractivenessPerArea(data, areaId) {
      const totalUniqueVisitors = _.get(data, 'total.standTraffic', 0)
      let areas = _.filter(_.get(data, 'abdAreas', []), { _id: areaId })
      const chartData = _.map(areas, area => {
        const areasPerDay = _.groupBy(_.get(data, 'abdTrafficAreas', []), 'date')
        const total = _.sum(
          _.map(areasPerDay, day => {
            return _.sum(
              _.compact(_.map(day, item => (item.area === area._id ? item.count : false)))
            )
          })
        )
        return Math.round((total / totalUniqueVisitors) * 100 * 100) / 100
      })
      return chartData
    },
    getTotalUniqueVisitors(data, forBarChart = false, forceDay = false) {
      let totalUniqueVisitors = _.get(data, 'total.standTraffic', 0)
      let trafficData = _.get(data, 'abdTraffic', [])
      let trafficForAreas = _.get(data, 'abdTrafficAreas', [])
      const forSecondary =
        _.get(data, 'pavilion._id', '?') !== _.get(this.primaryData, 'pavilion._id', '??')
      if (forceDay) {
        if (!forSecondary) {
          trafficData = _.filter(trafficData, { date: forceDay })
          trafficForAreas = _.filter(trafficForAreas, { date: forceDay })
          totalUniqueVisitors = _.sum(_.map(trafficData, 'count'))
        } else {
          trafficData = _.filter(trafficData, { date: forceDay })
          trafficForAreas = _.filter(trafficForAreas, { date: forceDay })
          totalUniqueVisitors = _.sum(_.map(trafficData, 'count'))
        }
      } else {
        let key = 'highlightedBubbleDay'
        let selectedKey = 'selectedBubbleDay'
        if (forBarChart) {
          key = 'highlightedAttractivenessDate'
          selectedKey = 'selectedAttractivenessDate'
        }
        if (!forSecondary && this[key] !== 0) {
          trafficData = _.filter(trafficData, { date: this[selectedKey] })
          trafficForAreas = _.filter(trafficForAreas, { date: this[selectedKey] })
          totalUniqueVisitors = _.sum(_.map(trafficData, 'count'))
        } else if (forSecondary && this[key + '2'] !== 0) {
          trafficData = _.filter(trafficData, { date: this[selectedKey + '2'] })
          trafficForAreas = _.filter(trafficForAreas, { date: this[selectedKey + '2'] })
          totalUniqueVisitors = _.sum(_.map(trafficData, 'count'))
        }
      }
      return {
        trafficData,
        trafficForAreas,
        totalUniqueVisitors
      }
    },
    getAttractivenessPerDay(data, forceArea = false) {
      const areaToFind =
        forceArea || this.selectedAttractiveness || (this.pdfArea === false ? 0 : this.pdfArea)
      const abdTrafficAreas = _.get(data, 'abdTrafficAreas', [])
      if (areaToFind === 0) {
        return _.map(this.groupAttractivenessByArea(data), record => {
          return { area: record.areaId, count: record.count }
        })
      }
      const areaPerDay = _.orderBy(
        _.filter(abdTrafficAreas, { area: areaToFind }),
        ['date'],
        ['asc']
      )
      const area = _.find(_.get(this.primaryData, 'abdAreas', []), { _id: areaToFind })
      const pois = _.get(this.primaryData, 'pois', [])
      const vehicles = _.get(this.primaryData, 'vehicles', [])
      const test = _.map(areaPerDay, day => {
        const { totalUniqueVisitors: totalForDay } = this.getTotalUniqueVisitors(
          data,
          true,
          day.date
        )
        const poi = this.findByAreaId(pois, area.id)
        const vehicle = this.findByAreaId(vehicles, area.id)
        const visualIdentifier = this.getVisualIdentifier(poi, vehicle)
        let label = _.get(area, 'abdName', '')
        if (!_.isUndefined(vehicle)) {
          label = TranslateService.get(vehicle.nameplate)
        } else if (!_.isUndefined(poi)) {
          label = TranslateService.get(poi.name)
        }
        const total = _.get(_.find(abdTrafficAreas, { area: area._id, date: day.date }), 'count', 0)
        const percentage = Math.round((total / totalForDay) * 100 * 100) / 100
        return {
          area: area.id,
          label: `${visualIdentifier} - ${label}\n${percentage}%`,
          count: percentage
        }
      })
      return _.map(test, 'count')
    },
    formatBarData(data, perHour, barType) {
      if (barType === 'Attractiveness') {
        return this.getAttractivenessPerDay(data)
      }
      if (barType === 'Area') {
        return perHour ? this.getAreaPerHour(data) : this.getAreaPerDay(data)
      }
      const field = barType === 'StayAverage' ? 'stayAverage' : 'count'
      if (!perHour) {
        return _.map(_.get(data, 'abdTraffic'), field)
      }
      const highlightedValue =
        field === 'stayAverage' ? this.highlightedStayAverage : this.highlightedTrafficValue
      if (barType === 'StayAverage' && perHour && highlightedValue === 0) {
        return _.map(_.groupBy(data.abdTrafficHours, 'time'), hour => _.mean(_.map(hour, field)))
      }
      if (highlightedValue === 0) {
        return _.map(_.groupBy(data.abdTrafficHours, 'time'), hour => _.sum(_.map(hour, field)))
      }
      return _.map(
        _.compact(_.get(data, `abdByDate[${highlightedValue - 1}].visitsByHour`, [])),
        field
      )
    },
    createBarDataset(data, datasetIndex, perHour, barType) {
      const dataset = {
        datasetIndex,
        label: _.get(data, 'pavilion.event.key', ''),
        backgroundColor: ChartsDataService.barColors[datasetIndex],
        barPercentage: 0.5
      }
      if (datasetIndex === 0 && !this.secondaryData) {
        dataset.label = TranslateService.get('T_ON_STAND_VISITORS')
      }
      const formattedData = this.formatBarData(data, perHour, barType)
      if (
        barType === 'Attractiveness' &&
        (this.selectedAttractiveness || (this.pdfArea === false ? 0 : this.pdfArea)) === 0
      ) {
        dataset.areasOrder = _.map(formattedData, 'area')
        dataset.data = _.map(formattedData, 'count')
      } else {
        dataset.data = formattedData
      }
      return dataset
    },
    getBarType(highlighted) {
      return _.find(['Traffic', 'StayAverage', 'Area', 'Attractiveness'], val =>
        _.includes(highlighted, val)
      )
    },
    msToTime(value) {
      let tmp = 0
      const negativeVal = value < 0
      if (negativeVal) {
        value = value * -1
      }
      tmp = numeral(value / 1000).format('00:00:00')
      if (_.get(_.split(tmp, ':'), '[0].length', 0) === 1) {
        return `${negativeVal ? '-' : ''}0${tmp}`
      }
      return negativeVal ? tmp * -1 : tmp
    },
    getBarData(highlighted) {
      const perHour = _.includes(highlighted, 'Hour')
      const attractiveness = _.includes(highlighted, 'Attractiveness')
      const barType = this.getBarType(highlighted)
      const datasets = _.map(
        _.compact([this.primaryData, this.secondaryData]),
        (data, datasetIndex) => {
          return this.createBarDataset(data, datasetIndex, perHour, barType)
        }
      )
      // console.warn(`datasets `, datasets)
      const attractivenessAreasOrder = _.get(datasets, `[0].areasOrder`, false)
      const barData = {
        data: {
          datasets: datasets,
          labels: this.getTrafficLabels(
            false,
            perHour,
            attractiveness,
            attractivenessAreasOrder,
            true
          ),
          chartSelectorLabels: this.getTrafficLabels(
            !perHour,
            perHour,
            attractiveness,
            attractivenessAreasOrder
          )
        }
      }
      barData.options = {
        plugins: {
          legend: {
            display: false
          }
        }
      }
      if (highlighted === 'highlightedTrafficValue') {
        _.set(
          barData,
          'data.datasets[0].weatherStatuses',
          this.secondaryData ? [] : _.map(_.get(this.primaryData, 'weather', []), 'status')
        )
        if (!this.secondaryData) {
          barData.options.onClick = (evt, activeElements) => {
            this.onClickBarGraph(evt, activeElements, highlighted)
          }
          if (!perHour) {
            barData.options.plugins.datalabels = {
              display: true,
              font: {
                family: 'weatherFont',
                size: 12
              },
              opacity: 1,
              labels: {
                title: {
                  formatter: this.weatherFormatter,
                  anchor: 'end',
                  align: 'top',
                  color: context => {
                    return this.getWeatherColors(
                      context.dataset.datasetIndex === 0 ? this.primaryData : this.secondaryData
                    )
                  }
                }
              }
            }
          }
        }
      }
      if (barType === 'StayAverage') {
        _.set(barData.options, 'scales.y.ticks.callback', value => this.msToTime(value))
        if (this.pdfPage) {
          _.set(barData, 'options.plugins.datalabels', {
            display: true,
            align: 'center',
            opacity: 1,
            labels: {
              title: {
                font: {
                  family: 'SourceSansPro-Bold',
                  size: 8,
                  weight: 700
                },
                anchor: 'end',
                align: 'top',
                offset: '0',
                color: 'black',
                formatter: (value, context) => {
                  return this.msToTime(_.get(context, `dataset.data[${context.dataIndex}]`, 0))
                }
              }
            }
          })
        }
      } else if (barType === 'Attractiveness') {
        _.set(barData.options, 'scales.y.ticks.callback', value => this.formatPercentage(value))
      }
      return barData
    },
    formatPercentage(val) {
      return `${Math.round(_.get(val, 'value', val) * 100) / 100}%`
    },
    onClickBarGraph(evt, activeElements, highlighted) {
      if (activeElements.length === 0) {
        return
      }
      _.set(this, highlighted, activeElements[0].index + 1)
    },
    getPieData(data, highlighted = false, demographyField = false, perArea = false) {
      if (!data) {
        return false
      }
      const labels = this.getPieLabels(data, demographyField)
      const pieData = {
        data: {
          // type: this.secondaryData || !highlighted || demographyField ? 'pie' : 'doughnut',
          type: 'pie',
          labels: demographyField ? [] : labels,
          indexes: labels
        }
      }
      if (highlighted && !demographyField) {
        const abdTrafficAreas = _.get(data, 'abdTrafficAreas', [])
        let trafficPerArea = _.map(data.abdAreas, area => {
          return {
            area: area.key,
            count: _.sum(_.map(_.filter(abdTrafficAreas, { area: area._id }), 'count'))
          }
        })
        trafficPerArea = _.map(_.orderBy(trafficPerArea, ['count'], ['desc']), 'count')
        _.set(pieData, 'data.datasets', [
          {
            index: _.map(trafficPerArea, (item, i) => `${i + 1}`),
            data: trafficPerArea,
            borderWidth: 0
          }
        ])
        _.set(pieData, 'options.onClick', (evt, activeElements) => {
          if (activeElements.length === 0) {
            return
          }
          _.set(this, highlighted, activeElements[0].index)
        })
      } else {
        _.set(pieData, 'data.datasets[0]', {
          data: this.getDemographyData(data, demographyField, highlighted, perArea),
          borderWidth: 0
        })
        this.cleanPieLabels(pieData)
      }
      if (!demographyField) {
        pieData.options = _.merge(pieData.options, {})
      }
      if (_.get(pieData, 'data.datasets[0].data.length', 0) === 0) {
        _.set(
          pieData,
          'data.datasets[0].data',
          _.map(pieData.data.indexes, () => 0)
        )
      }
      return pieData
    },
    getDay(dates, dayLabel) {
      const dateIndex = _.toNumber(_.get(_.split(dayLabel, ' '), '[1]', '1')) - 1
      return _.get(dates, `[${dateIndex}]`, '')
    },
    getDatesForCompare(data) {
      return this.secondaryData ? _.uniq(_.map(data, 'date')) : []
    },
    filterBy(data, opts, filterByHour = false) {
      const dates = this.getDatesForCompare(data)
      _.each(opts, (binding, key) => {
        if (_.get(this, key, 0) !== 0 || (!this.secondaryData && key === 'highlightedArea')) {
          let valToFind = _.get(this, binding.value, false)
          if (binding.field === 'date') {
            if (this.secondaryData) {
              valToFind = this.getDay(dates, valToFind)
            }
            valToFind = this.labelToDate(valToFind)
          }
          if (!_.includes(key, 'Hour') || (_.includes(key, 'Hour') && filterByHour)) {
            if (key === 'highlightedArea' && this.pdfArea) {
              valToFind = this.pdfArea
            }
            data = _.filter(data, item => {
              return _.get(item, binding.field, false) === valToFind
            })
          }
        }
      })
      return data
    },
    getAbdData(perArea, perHour) {
      if (perArea) {
        return perHour ? 'abdTrafficAreaHours' : 'abdTrafficAreas'
      }
      return perHour ? 'abdTrafficHours' : 'abdTraffic'
    },
    getDemographyData(data, field, highlighted = false, perArea = false) {
      const perHour = _.includes(highlighted, 'Hour')
      const dataObj = this.getAbdData(perArea, perHour)
      let filteredData = _.get(data, dataObj, [])
      const mappings = perArea ? this.areaMappings : this.stayAverageMappings
      filteredData = this.filterBy(filteredData, mappings, perHour)
      filteredData = _.map(filteredData, item => _.get(item, `demography.${field}`, {}))
      let keys = _.keys(_.get(filteredData, `[0]`, {}))
      if (_.isEmpty(keys) && field === 'gender') {
        keys = ['female', 'male']
      }
      return _.map(keys, key => {
        return _.sum(_.map(filteredData, key))
      })
    },
    getAreasLabels(data) {
      const areas = _.get(data, 'abdAreas', [])
      const pois = _.get(data, 'pois', [])
      const vehicles = _.get(data, 'vehicles', [])
      return _.map(areas, area => {
        return this.getVisualIdentifier(
          this.findByAreaId(pois, area.id),
          this.findByAreaId(vehicles, area.id)
        )
      })
    },
    getTrafficLabels(
      fullDate = false,
      perHour = false,
      forAttractiveness = false,
      attractivenessAreasOrder = false,
      checkIfMobile = false
    ) {
      if (this.secondaryData && !perHour) {
        return _.map(
          this.getTrafficDates(this.primaryData, perHour),
          (date, i) => TranslateService.get('T_DAY') + ` ${i + 1}`
        )
      }
      if (forAttractiveness) {
        if (attractivenessAreasOrder) {
          return _.map(attractivenessAreasOrder, area =>
            this.getAreaFullName(
              this.primaryData,
              'id',
              area,
              true,
              checkIfMobile ? this.mobileMode : false
            )
          )
        }
        const key = this.selectedAttractiveness ? 'date' : 'abdName'
        const obj = this.selectedAttractiveness ? 'abdTrafficAreas' : 'abdAreas'
        let data = _.map(_.get(this.primaryData, obj, []), key)
        if (this.selectedAttractiveness) {
          data = _.uniq(data)
        }
        let labels = _.compact(data)
        if (key !== 'date') {
          // const visualIdentifiers = this.getAreasLabels(this.primaryData)
          return _.map(_.get(this.primaryData, 'abdAreas', []), area =>
            this.getAreaFullName(this.primaryData, 'id', area.id, true)
          )
          // return _.map(labels, (label, i) => `${_.get(visualIdentifiers, `[${i}]`, '')} - ${label}`)
        }
        const formatting = fullDate ? 'YYYY/MM/DD' : 'MM/DD'
        labels = _.map(labels, label => moment(label).format(formatting))
        return labels
      }
      if (perHour) {
        return this.getTrafficDates(this.primaryData, perHour)
      }
      const formatting = fullDate ? 'YYYY/MM/DD' : 'MM/DD'
      return _.map(this.getTrafficDates(this.primaryData, perHour), date =>
        moment(date).format(formatting)
      )
    },
    getTrafficDates(data, perHour = false) {
      if (perHour) {
        return _.get(data, 'hoursRange', [])
      }
      const trafficDatesLabels = _.compact(
        _.map(_.get(data, 'abdTraffic'), abdTrafficDay => _.get(abdTrafficDay, 'date', false))
      )
      return trafficDatesLabels
    },
    weatherFormatter(value, context) {
      if (value === 0 || _.get(context, 'dataset.weatherStatuses.length', 0) === 0) {
        return ''
      }
      const weather = _.get(context, `dataset.weatherStatuses[${context.dataIndex}]`, 'not-defined')
      if (_.isUndefined(this.weatherMap[weather])) {
        console.warn('weather option not defined: ', weather, context.dataIndex)
      }
      return this.weatherMap[weather] || ''
    }
  }
}
</script>
